import React from 'react'
import Nav from '../Nav'
import { CSSTransition } from 'react-transition-group'

function Header () {
    return (
            <header id='header'>
                <h1 className='headers'>Andy Sanchez</h1>
            </header>
    )
}

export default Header;